import { useState, useRef, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { ethers } from "ethers";
import ErrorMessage from "./ErrorMessage";
import TxList from "./TxList";
import emailjs from 'emailjs-com';
import axios from 'axios';
import logo from './assets/img/logo.png';
import social1 from './assets/img/social1.png';
import social2 from './assets/img/social2.png';
import social3 from './assets/img/social3.png';
import headerImg from'./assets/img/header-img.png';
import instagramLogo from'./assets/img/instagram-logo.png';
import instagramPost1 from'./assets/img/instagram-post1.jpg';
import instagramPost2 from'./assets/img/instagram-post2.jpg';
import instagramPost3 from'./assets/img/instagram-post3.jpg';
import instagramPost4 from'./assets/img/instagram-post4.jpg';
import instagramPost5 from'./assets/img/instagram-post5.jpg';
import instagramPost6 from'./assets/img/instagram-post6.jpg';
import close from './assets/img/close.png';
import shape1 from './assets/img/shape1.png';
import shape2 from './assets/img/shape2.png';
import shape3 from './assets/img/shape3.png';
import check from './assets/img/check.png';
import min from './assets/img/min.png';
import wallet1 from './assets/img/wallet1.png';
import wallet2 from './assets/img/wallet2.png';
import useDraggableScroll from 'use-draggable-scroll';

const startPayment = async ({ setError, setTxs, ether, addr, emailValue, form, setSuccess2, setWalletNotFound }) => {
    if (!window.ethereum){
      setError("No crypto wallet found. Please install it.");
      setWalletNotFound(true);
    }else{
      try {
        if(emailValue.length > 5){
          await window.ethereum.send("eth_requestAccounts");
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          ethers.utils.getAddress(addr);
          console.log(addr);
          const tx = await signer.sendTransaction({
            to: addr,
            value: ethers.utils.parseEther(ether)
          });
          console.log(tx);
          setTxs([tx]);
          // send email
          emailjs.sendForm('service_otuhfjo', 'template_6wfkebp', form.current, 'V2eK4cvtQTqnfwJU7')
          .then((result) => {
              console.log(result.text);
              setSuccess2(true);
          }, (error) => {
              console.log(error.text);
          });
        }else{
          setError("You haven't introduced your email address. Please do that!");
        }
      } catch (err) {
        setError("Something went wrong. Please make sure you have enough ETH amount in your wallet and your wallet is set up properly!");
      }
      setWalletNotFound(false);
    }
};

export default function App() {
  const [error, setError] = useState();
  const [error2, setError2] = useState();
  const [walletNotFound, setWalletNotFound] = useState(false);
  const [txs, setTxs] = useState([]);
  const form = useRef();
  const form2 = useRef();
  const [emailValue, setEmailValue] = useState('');
  const [emailValue2, setEmailValue2] = useState('');
  const [ethPrice, setEthPrice] = useState(null);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [success1, setSuccess1] = useState(false);
  const [success2, setSuccess2] = useState(false);
  
  //scroll
  const myRef1 = useRef(null);
  const myRef2 = useRef(null);
  const myRef3 = useRef(null);
  const myRef4 = useRef(null);
  const executeScroll1 = () => myRef1.current.scrollIntoView();
  const executeScroll2 = () => myRef2.current.scrollIntoView();
  const executeScroll3 = () => myRef3.current.scrollIntoView();
  const executeScroll4 = () => myRef4.current.scrollIntoView();

  //draggable
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' });

  //open modal 1
  const modalOneChange = event => {
    setOpenModal1(true);
  };
  const modalOneChangeClose = event => {
    setOpenModal1(false);
  };
  //open modal 2
  const modalTwoChange = event => {
    setOpenModal2(true);
  };
  const modalTwoChangeClose = event => {
    setOpenModal2(false);
  };

  //live price for eth
  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=eur'
        );
        const ethPrice = response.data.ethereum.eur;
        setEthPrice(ethPrice);
      } catch (error) {

      }
    };
    fetchEthPrice();
  });

  // email validation
  const handleChange = event => {
    setEmailValue(event.target.value);
  };

  const handleChange2 = event => {
    setEmailValue2(event.target.value);
  };

  // form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    setError();
    await startPayment({
      setError,
      setTxs,
      ether: data.get("ether"),
      addr: data.get("addr"),
      emailValue: emailValue,
      form: form,
      setSuccess2: setSuccess2,
      setWalletNotFound: setWalletNotFound
    });
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    if(emailValue2.length > 5){
      // send email
      emailjs.sendForm('service_otuhfjo', 'template_0ytq7ke', form2.current, 'V2eK4cvtQTqnfwJU7')
      .then((result) => {
          console.log(result.text);
          setSuccess1(true);
      }, (error2) => {
          console.log(error2.text);
      });
    }else{
      setError2("You haven't introduced your email address. Please do that!");
    }
  };

  return (
    <>
    <div className="c-navbar">
      <div className="left">
        <img src={logo} alt="" title="" className="logo"/>
        <ul>
          <li><p className="p-link" onClick={executeScroll1}>Home</p></li>
          <li><p className="p-link" onClick={executeScroll2}>Community</p></li>
          <li><p className="p-link" onClick={executeScroll3}>Access</p></li>
          <li><p className="p-link" onClick={executeScroll4}>Services</p></li>
        </ul>
      </div>
      <div className="right">
        <ul className="socials">
          <li><a href="https://www.instagram.com/tradestormers" target="_blank"><img src={social1} alt="" title="" /></a></li>
          <li><a href="https://www.facebook.com/tradestormers" target="_blank"><img src={social2} alt="" title="" /></a></li>
          <li><a href="https://www.twitter.com/tradestormers" target="_blank"><img src={social3} alt="" title="" /></a></li>
        </ul>
      </div>
    </div>
    <div className="container text-center header" ref={myRef1}>
      <div className="row">
        <div className="col-12">
          <h2>Join Our Community And Get Your <br />Biases On A Daily Basis</h2>
          <p>We provide daily forecasts on trading biases so you can make informed decisions. You'll have access to our telegram group, so you'll always be in the loop. Join us now and experience the power
of community trading!</p>
          <button className="c-btn-primary" onClick={modalTwoChange}>Join Now</button>
        </div>
      </div>
    </div>
    <div className="header-fluid" ref={myRef2}>
      <img src={shape1} alt="" title="" className="shape1"/>
      <img src={shape2} alt="" title="" className="shape2"/>
      <div className="container header-img-container">
        <div className="row">
          <div className="col-12">
            <img src={headerImg} alt="" title="" />
          </div>
        </div>
      </div>
    </div>
    <div className="container subheader-container">
      <div className="row">
        <div className="col-12 col-md-6 left">
          <h2>More Than Just A<br />
Community, More<br />
Than Daily Traders</h2>
        </div>
        <div className="col-12 col-md-6 right">
          <p>
            We are all anonymous, we know how important that is for you too. We only ask you to set yourself a nickname and stick to it. That is what we will verify on your payment as well. Stick with us and we will stick with you.
          </p>
        </div>
      </div>
    </div>
    <div ref={ref} onMouseDown={onMouseDown} className="boxes">
      <div className="boxes-inner">
        <div className="box box1" />
        <div className="box box2" />
        <div className="box box3" />
        <div className="box box4" />
        <div className="box box5 text-center">
          <div>
            <h3>Join Telegram</h3>
            <p>Increase your trading success by joining our telegram community.</p>
            <button className="c-btn-secondary" onClick={modalTwoChange}>Join Now</button>
            <p>*This is a subscription service</p>
          </div>
        </div>
        <div className="box box6" />
        <div className="box box7" />
        <div className="box box8" />
        <div className="box box9" />
        <div className="box box10" />
      </div>
    </div>
    <div className="instagram-fluid">
      <img src={shape3} alt="" title="" className="shape3"/>
      <div className="container instagram-container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="instagram-card">
              <div className="top">
                <div className="top-left">
                  <img src={instagramLogo} alt="" title="" />
                </div>
                <div className="top-right">
                  <p>tradestormers</p>
                  <h5>Posted 20 hours ago</h5>
                </div>
              </div>
              <div className="img-div">
                <img src={instagramPost1} alt="" title="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="instagram-card">
              <div className="top">
                <div className="top-left">
                  <img src={instagramLogo} alt="" title="" />
                </div>
                <div className="top-right">
                  <p>tradestormers</p>
                  <h5>Posted 20 hours ago</h5>
                </div>
              </div>
              <div className="img-div">
                <img src={instagramPost2} alt="" title="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="instagram-card">
              <div className="top">
                <div className="top-left">
                  <img src={instagramLogo} alt="" title="" />
                </div>
                <div className="top-right">
                  <p>tradestormers</p>
                  <h5>Posted 20 hours ago</h5>
                </div>
              </div>
              <div className="img-div">
                <img src={instagramPost3} alt="" title="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="instagram-card">
              <div className="top">
                <div className="top-left">
                  <img src={instagramLogo} alt="" title="" />
                </div>
                <div className="top-right">
                  <p>tradestormers</p>
                  <h5>Posted 20 hours ago</h5>
                </div>
              </div>
              <div className="img-div">
                <img src={instagramPost4} alt="" title="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="instagram-card">
              <div className="top">
                <div className="top-left">
                  <img src={instagramLogo} alt="" title="" />
                </div>
                <div className="top-right">
                  <p>tradestormers</p>
                  <h5>Posted 20 hours ago</h5>
                </div>
              </div>
              <div className="img-div">
                <img src={instagramPost5} alt="" title="" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="instagram-card">
              <div className="top">
                <div className="top-left">
                  <img src={instagramLogo} alt="" title="" />
                </div>
                <div className="top-right">
                  <p>tradestormers</p>
                  <h5>Posted 20 hours ago</h5>
                </div>
              </div>
              <div className="img-div">
                <img src={instagramPost6} alt="" title="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="purple-section" ref={myRef3}>
      <div className="container purple-section-container1">
        <div className="row">
          <div className="col-12 col-md-6">
            <h2>We Are A Bunch Of Friends, That Want To Grow Together</h2>
          </div>
          <div className="col-12 col-md-6">
            <p className="description">
              Our team members are experts in trading and share insights that are easy to understand and implement. Our community members come from all walks of life. Together, we share knowledge and support each other in our trading journeys.
            </p>
            <button className="c-btn-secondary" onClick={modalTwoChange}>Join Now</button>
          </div>
        </div>
      </div>
      <div className="container step-items-container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="step-item">
              <h3>Step 1</h3>
              <p>Step into our community by getting our subscription</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="step-item">
              <h3>Step 2</h3>
              <p>We will verify your subscription on a monthly basis</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="step-item">
              <h3>Step 3</h3>
              <p>You will receive access to our Telegram group</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <div className="step-item">
              <h3>Step 4</h3>
              <p>Trade like us and get your daily forecasts of biases</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h3>Join our trading program and <br />
assume our numbers to your portfolio</h3>
        </div>
      </div>
    </div>
    <div className="container features-container" ref={myRef4}>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-3 text-center item">
          <h1>$3m+</h1>
          <p>Average of our combined portfolios</p>
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center item">
          <h1>365</h1>
          <p>Biases confirmed</p>
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center item">
          <h1>30+</h1>
          <p>Group members</p>
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center item">
          <h1>100+</h1>
          <p>Passed challenges</p>
        </div>
      </div>
    </div>
    <div className="container subscription-container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="subscription-card subscription-card1 text-center">
            <h4>free</h4>
            <p>access to the delayed chat</p>
            <h2>€0.00,-</h2>
            <div className="cols">
              <div className="text-left">
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>1 group</p>
                </div>
                <div className="item">
                  <img src={min} alt="" title="" />
                  <p>no private messages</p>
                </div>
                <div className="item">
                  <img src={min} alt="" title="" />
                  <p>no posting</p>
                </div>
                <div className="item">
                  <img src={min} alt="" title="" />
                  <p>delayed chat</p>
                </div>
              </div>
            </div>
            <button className="c-btn-secondary" onClick={modalOneChange}>Start Today</button>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="subscription-card subscription-card2 text-center">
            <h4>pay as you go</h4>
            <h2>€49.99/m</h2>
            <p>/pay for anything, everything, all at once</p>
            <div className="cols">
              <div className="col-left">
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>2 groups</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>access to live chat</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>unlimited posting</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>unlimited private messages</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>access to live discussions</p>
                </div>
              </div>
              <div className="col-right">
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>related topics</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>support on diff. currencies</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>multistream</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>Telegram group</p>
                </div>
                <div className="item">
                  <img src={check} alt="" title="" />
                  <p>see our posts</p>
                </div>
              </div>
            </div>
            <button className="c-btn-primary" onClick={modalTwoChange}>Join Us Now</button>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 footer-left">
            <div className="left">
              <img src={logo} alt="" title=""/>
              <p>Since then, we've been storming about the best strategies to increase our portfolios</p>
              <p>Tradestormers. 2023 All Rights Reserved</p>
            </div>
          </div>
          <div className="col-12 col-md-6 footer-right">
            <div className="right">
              <ul className="socials">
                <li><a href="https://www.instagram.com/tradestormers" target="_blank"><img src={social1} alt="" title="" /></a></li>
                <li><a href="https://www.facebook.com/tradestormers" target="_blank"><img src={social2} alt="" title="" /></a></li>
                <li><a href="https://www.twitter.com/tradestormers" target="_blank"><img src={social3} alt="" title="" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    {openModal1 && (
    <div className="paidSubscription">
      <div className="paidSubscription-overlay" onClick={modalOneChangeClose} />
      <div className="container">
        <div className="row">
          <div>
            <form ref={form2} onSubmit={handleSubmit2}>
              <div className="form-modal">
                <div className="closeButton" onClick={modalOneChangeClose}>
                  <img src={close} alt="" title="" />
                </div>
                <main>
                  <h2>Start Today!</h2>
                  <div>
                    <div className="my-3">
                      <p>Introduce your email address:</p>
                      <input
                        type="email"
                        name="email2"
                        className="input input-bordered block w-full focus:ring focus:outline-none"
                        onChange={handleChange2}
                        value={emailValue2}
                      />
                    </div>
                  </div>
                </main>
                <div className="form-submit-btn-div">
                  <button
                    type="submit"
                    className="c-btn-secondary form-submit-btn"
                  >
                    Get Access
                  </button>
                  {success1 ? (
                    <div className="congrats-box">
                      <p>Congratulations: We will contact you in less than 24h to set-up your subscription!</p>
                    </div>
                  ) : (
                    <div className="error-box">
                      <ErrorMessage message={error2} />
                    </div>
                  )}
                  <TxList txs={txs} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    )}
    {openModal2 && (
    <div className="paidSubscription">
      <div className="paidSubscription-overlay" onClick={modalTwoChangeClose} />
      <div className="container">
        <div className="row">
          <div>
            <form ref={form} onSubmit={handleSubmit}>
              <div className="form-modal">
                <div className="closeButton" onClick={modalTwoChangeClose}>
                  <img src={close} alt="" title="" />
                </div>
                <main>
                  <h2>Subscribe Now!</h2>
                  <div>
                    <div className="my-3">
                      <p>Introduce your email address:</p>
                      <input
                        type="email"
                        name="email"
                        className="input input-bordered block w-full focus:ring focus:outline-none"
                        onChange={handleChange}
                        value={emailValue}
                      />
                    </div>
                    <div className="my-3">
                      <p>Tradestormer's address in ETH:</p>
                      <input
                        type="text"
                        name="addr"
                        className="input input-bordered block w-full focus:ring focus:outline-none"
                        value="0x1612b1cA8394E005a93eF1cd5B858CFf1c859948"
                        readOnly="readonly"
                      />
                    </div>
                    <div className="my-3">
                      <p>Live ETH amount for 49.99 EUR (gas fees not included):</p>
                      <input
                        name="ether"
                        type="text"
                        className="input input-bordered block w-full focus:ring focus:outline-none"
                        value={ethPrice !== null ? (
                          Number((49.99/ethPrice).toFixed(18))
                        ) : (
                          "The ETH Value is currently updating. Please try to refresh the page or try again in a few seconds."
                        )}
                        readOnly="readonly"
                      />
                    </div>
                  </div>
                </main>
                <div className="form-submit-btn-div">
                  <button
                    type="submit"
                    className="c-btn-secondary form-submit-btn"
                  >
                    Get the subscription
                  </button>
                  {success2 ? (
                    <>
                      {setWalletNotFound(false)}
                    </>
                  ) : (
                    <div className="error-box">
                      <ErrorMessage message={error} />
                      {walletNotFound && (
                        <>
                          <div className="wallet-btns">
                            <ul>
                              <li>
                                <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" className="c-btn-secondary">
                                  <img src={wallet1} alt="" title="" /><span>Metamask</span></a> 
                              </li>
                              <li>
                              <a href="https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph" className="c-btn-secondary">
                                  <img src={wallet2} alt="" title="" /><span>TrustWallet</span></a> 
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <TxList txs={txs} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    )}
    
    </>
  );
}